// import { Theme } from './theme.types';

const baseTheme = {
  screen: {
    tabletLandscapeMinimum: '992px',
    desktopMinimum: '1200px'
  }
};

export const defaultTheme = {
  name: 'Dark Theme',
  colors: {
    background: '#343232',
    text: '#ffffff',
    highlight: '#b60000',
    primary: '#ffffff',
    secondary: '#e9e9e9'
  },
  fonts: {
    body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px'
  },
  responsive: {
    small: '35rem',
    medium: '50rem',
    large: '70rem'
  },
  ...baseTheme
};
