export const globalColors = [
  'background',
  'text',
  'primary',
  'secondary',
  'tertiary',
  'highlight'
] as const;

export type GlobalColors = typeof globalColors[number];
