const React = require('react');
const { ThemeProvider } = require('styled-components');
const { defaultTheme, GlobalStyles } = require('./src/styles');

exports.wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      {element}
    </ThemeProvider>
  );
};
