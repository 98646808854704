import { createGlobalStyle } from 'styled-components';
import { ThemeProperties } from '../theme';

export const GlobalStyles = createGlobalStyle<ThemeProperties>`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
  }
  html,
  body {
    width: 100%;
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    line-height: 1.5;
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text}
  }
  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
`;
